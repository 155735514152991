@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Eagle+Lake&family=Macondo+Swash+Caps&family=Montez&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Syncopate:wght@400;700&family=The+Girl+Next+Door&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Long+Cang&display=swap');

html {
  scroll-behavior: smooth;
}

:root {
  --base-color: #94e794;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0a192f;
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto;
  cursor: none;
}

/* cursor */
.cursor{
  position: fixed;
  left: 10px;
  width: 5px;
  height: 5px;
  transform:translate(-50%, -50%);
  background-color: #94e794;
  mix-blend-mode: difference;
  border-radius: 50%;
  pointer-events: none;
  transition: width 0.5s, height 0.5s;
}

.outer-cursor.grow{
  width: 30px;
  height: 30px;
  /* border: 2px dashed #fff; */
  /* cursor: not-allowed; */
  /* pointer-events: none; */
  /* transition: width 0.5s, height 0.5s; */
  /* animation: 5s linear infinite; */
}

/* 
@keyframes animateH {
  0% {
    transform: (-50%, -50%) rotate(0 deg);
  }
  100% {
    transform: (-50%, -50%) rotate(360 deg);
  }
} */

.outer-cursor{
  position: fixed;
  left: 10px;
  width: 40px;
  height: 40px;
  transform:translate(-50%, -50%);
  /* background-color: #94e794; */
  border: 1px solid var(--base-color);
  mix-blend-mode: difference;
  border-radius: 50%;
  pointer-events: none;
  /* transition: width 0.5s, height 0.5s; */
  pointer-events: 0.1ms;
  transition: 0.1s ease-out;
}

.base-color {
  color: var(--base-color);
}

.center {
  text-align: center;
}

.heading {
  position: relative;
  text-align: center;
  font-size: 70px;
  color: rgba(225, 255, 255, 0.1);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 8px;
  transition: 0.5s;
  margin-top: 150px;
}

.heading::before {
  content: attr(data-text);
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: var(--base-color);
  text-shadow: 0 0 10px var(--base-color);
  transition: 0.5s;
  font-weight: 500;
  letter-spacing: 8px;
  white-space: nowrap;
  text-align: center;
  opacity: 0.8;
  transition-delay: 0.5s;
}

/* header */

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.container {
  max-width: 1170px;
  margin: auto;
}
.header {
  padding: 12px 0;
  position: relative;
}
header.sticky {
  background-color: #0e1f38f7;
  padding: 0px 0px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.header .logo {
  color: aliceblue;
  /* font-family: 'The Girl Next Door', cursive; */
  /* font-family: 'Macondo Swash Caps', cursive; */
  /* font-family: 'Syncopate', sans-serif; */
  font-family: 'Long Cang', cursive;
}

.logo h1 {
  font-size: 40px;
}

.header #navBar ul li {
  display: inline-block;
  padding: 0px 15px;
  /* margin-left: 40px; */
  /* justify-content: space-between; */
}
.header #navBar ul li:nth-last-child(){
  padding: 0px;
}
.header #navBar ul li a {
  position: relative;
  color: rgb(224, 208, 208);
  transition: all 0.5s ease;
}

.header #navBar ul li a:hover {
  color: aliceblue;
}

.header #navBar ul li a:after {
  content: '';
  position: absolute;
  background-color: #94e794;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
}

.header #navBar ul li a:hover:after {
  width: 100%;
}

/* mobile menu */

.header #nav-check {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
#toggle {
  position: absolute;
  /* display: flex; */
  margin-right: 15px;
}

@media (max-width: 991px) {
  header.sticky {
    padding: 0px 40px;
  }
  #toggle {
    position: relative;
    display: flex;
    /* overflow: hidden; */
    /* margin-right: 15px; */
  }

  #toggle span {
    position: absolute;
    width: 40px;
    height: 3px;
    background-color: var(--base-color);
    border-radius: 4px;
    transition: 0.5s;
  }

  #toggle span:nth-child(1) {
    transform: translateY(-15px);
    width: 25px;
  }

  #toggle.active span:nth-child(1) {
    width: 40px;
    transform: translateY(0px) rotate(45deg);
    transition-delay: 0.125s;
  }

  #toggle span:nth-child(2) {
    transform: translateY(15px);
    width: 15px;
  }
  #toggle.active span:nth-child(2) {
    width: 40px;
    transform: translateY(0px) rotate(315deg);
    transition-delay: 0.25s;
  }
  #toggle.active span:nth-child(3) {
    /* transform: translateX(105px); */
    /* z-index: 100; */
    display: none;
  }
  .header #navBar {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #0e1f38f7;
    /* border-top: 1px solid rgba(225, 255, 255, 0.1); */
    opacity: 0;
    visibility: hidden;
  }
  .header #navBar ul li {
    display: inline-flex;
    text-align: center;
    line-height: 30px;
    
    /* border-top: 1px solid #0a192f; */
  }

  .header #navBar ul{
    margin-left: -50px;
    text-align: center;
  }
  .header #nav-check:checked ~ #navBar {
    opacity: 1;
    visibility: visible;
  }
}

/* hero section */

.hero {
  margin: 50px 0px;
  line-height: 26px;
  display: flex;
  margin-bottom: 150px;
}

.hero h2 {
  color: aliceblue;
}

.hero h1 {
  font-size: 60px;
  font-weight: 400;
  color: aliceblue;
}

.hero .tag {
  font-size: 55px;
  color: rgb(193, 198, 202);
}

.hero .desc {
  color: darkgray;
  font-size: 18px;
  width: 60%;
}

.a-link {
  color: aliceblue;
  font-size: 18px;
  text-decoration: none;
}

.hero .hero-right h2 {
  position: relative;
  font-size: 14vh;
  /* text-align: center; */
  /* text-transform: uppercase; */
  line-height: 90px;
  color: #0a192f;
  -webkit-text-stroke: 0.3vh #0f2649;
}

.hero .hero-right h2::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  /* color: aquamarine; */
  -webkit-text-stroke: 0.3vh var(--base-color);
  border-right: 2px solid #0f2649;
  overflow: hidden;
  animation: animate 6s linear infinite;
}

@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
}

/* about */
.about-section {
  margin-bottom: 150px;
}
.about {
  display: flex;
  margin: 80px auto;
}
.web-hidden {
  display: none;
}
.left-side {
  width: 50%;
}

.right-side {
  width: 50%;
}

.about h1 {
  font-weight: bold;
  color: #6894d545;
  font-size: 50px;
  margin-bottom: 10px;
}

.about h2 {
  color: aliceblue;
  font-size: 35px;
}

.about-info {
  display: flex;
  /* justify-content: space-evenly; */
  margin-bottom: 5px;
  line-height: 20px;
}

.about-info .number,
.about-info .info {
  display: block;
  text-align: center;
  color: darkgray;
}

.number h1 {
  font-size: 30px;
  font-weight: bold;
  color: var(--base-color);
  margin-bottom: 10px;
}

.info {
  font-size: 18px;
}

.right-side p {
  color: darkgray;
  font-size: 18px;
  line-height: 26px;
}

.pd {
  margin-right: 40px;
}
.pro-lang {
  width: 85%;
  margin: 0 auto;
  margin-top: -40px;
}
.pro-lang img {
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  margin: 10px;
  border: none;
  outline: none;
  background-color: #0a192f;
  box-shadow: -1px -1px 3px rgba(225, 255, 255, 0.1),
    2px 2px 6px rgba(0, 0, 0, 0.8);
}

.pro-lang img:hover {
  box-shadow: -1px -1px 3px rgba(6, 20, 42, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.8),
    inset -2px -2px 10px #0a192f, inset 2px 2px 10px rgba(0, 0, 0, 0.5);
}

/* experience */

.qualification {
  width: 40%;
  /* height: 100vh; */
  margin: 70px auto;
  margin-bottom: 40px;
}

.qualification-container {
  margin-bottom: 40px;
}
.qualification-tabs {
  display: flex;
  justify-content: center;
}

.tabs {
  color: aliceblue;
  font-size: 18px;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 30px;
}

.tabs i,
.date i {
  margin-right: 5px;
}

.active-tabs {
  color: var(--base-color);
}
.data {
  color: antiquewhite;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 60px;
}
.title {
  color: aliceblue;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  display: inline-block;
  margin-bottom: 10px;
  color: darkgrey;
}

.date {
  color: gray;
}

.rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--base-color);
  border-radius: 50%;
}

.line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--base-color);
  transform: translate(6px, -7px);
}

.content {
  display: none;
}

.active-content {
  display: block;
}

/* work */

.work {
  position: relative;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: minmax(100px, auto);
  /* margin: 40px; */
  grid-auto-flow: dense;
  grid-gap: 10px;
}

.work .project {
  background: rgba(255, 255, 255, 0.02);
  padding: 20px;
  display: grid;
  place-items: center;
  text-align: center;
  color: #fff;
  transition: 0.5s;
}

.work .project:hover {
  background: #0a192f;
}

.work .project a {
  text-decoration: none;
  color: darkgrey;
}
.work .project img {
  position: relative;
  width: 150px;
  max-width: 100%;
  margin-bottom: 10px;
}

.work .project:nth-child(1) {
  grid-column: span 2;
  grid-row: span 1;
}

.work .project:nth-child(6) {
  grid-column: span 2;
  grid-row: span 1;
}

.work .project:nth-child(7) {
  grid-column: span 2;
  grid-row: span 1;
}

@media (max-width: 991px) {
  .work {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-template-rows: minmax(auto, auto);
  }

  .work .project {
    grid-column: unset !important;
    grid-row: unset !important;
  }
}

/* additional work */

.additional-project {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: dense;
  grid-gap: 15px;
  margin-top: 50px;
}
.project-info {
  background-color: #132846f7;
  padding: 30px;
  border-radius: 5px;
}
.project-info:hover {
  margin-top: -8px;
  transition: 0.2s;
}
.additional-project .top {
  font-size: 20px;
}
.additional-project .top i {
  color: var(--base-color);
  margin-right: 15px;
}
.additional-project .top span {
  color: aliceblue;
}

.details p {
  color: darkgray;
  font-size: 18px;
  display: block;
}

.details ul {
  display: flex;
  column-gap: 10px;
  padding-left: 0px;
}
.details ul li {
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #0a192f;

  list-style-type: none;
  color: var(--base-color);
}

.project-link {
  text-align: right;
}
.project-link a {
  color: darkgray;
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
}

/* footer */

.footer {
  margin: 150px auto;
  padding: 60px;
  text-align: center;
  background: rgba(255, 255, 255, 0.02);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.footer a:hover {
  color: aliceblue;
  background-color: #0a192f;
}

.footer h2 {
  font-size: 18px;
  color: var(--base-color);
}

.footer h1 {
  color: aliceblue;
}

.footer p {
  color: darkgrey;
  font-size: 18px;
  margin-bottom: 50px;
}

.footer a {
  text-decoration: none;
  padding: 20px;
  background: #0f2649;
  color: var(--base-color);
  border-radius: 5px;
}
.call-btn{
  /* text-align: right; */
  position: fixed;
  right: 20px;
  bottom: 20px;

}
.call-btn .button{
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 0 5px;
  overflow: hidden;
  cursor: pointer;
  background-color: #0f2649;
  border-radius: 50px;
  box-shadow: 0px 10px 10px  rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.call-btn .button:hover{
  width: auto;
  padding-right: 20px;
  transition: all 0.3s ease-out;
}
.call-btn .button .icon{
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 50px;
  transition: all 0.3s ease-out;
}
.call-btn .button:hover .icon{
  background-color: #0a192f;
  transition: all 0.3s ease-out;

}
.call-btn .button .icon i{
  font-size: 17px;
  line-height: 50px;
  color:aliceblue;
  transition: all 0.3s ease-out;
}
.call-btn .button:hover .icon i{
  color: var(--base-color);
}
.call-btn .button span{
  font-size: 17px;
  font-weight: 500;
  line-height: 50px;
  margin-left: 10px;
  color:aliceblue;
  
}
.build-by {
  display: flex;
  margin: 20px 0px;
  justify-content: space-between;
}

.build-by h1 {
  color: aliceblue;
  font-size: 17px;
  font-weight: 500;
}

.build-by a {
  color: aliceblue;
  padding: 0px 7px;
}

/* responsive */

@media (max-width: 991px) {
  .cursor{
    display: none;
    /* cursor: pointer; */
  }
  .outer-cursor.grow{
    display: none;
  }
  .outer-cursor{
    display: none;
    /* cursor: pointer; */
  }
  
  /* hero section */
  .heading{
    font-size: 42px;
    margin-top: 100px;
  }
  .hero {
    display: block;
  }

  .hero h1{
    font-size: 42px;
  }
  .logo h1 {
    font-size: 35px;
  }
  .hero .desc {
    font-size: 18px;
    width: 100%;
  }

  .hero .hero-right h2 {
    font-size: 5vh;
  }
  .hero .tag {
    font-size: 44px;
    line-height: normal;
}
  .hero .hero-right h2::before {
    height: 100%;
  }

  .hero .tag {
    font-size: 44px;
  }

  /* about */

  .about {
    display: flex;
    flex-direction: column-reverse;
    /* align-items: center; */
  }
  .about h1{
    font-size: 35px;
  }

  .about-info {
    margin-left: 50px;
}
  .right-side {
    width: 100%;
    margin: auto;
    text-align: centerS;
  }
  .res-hidden {
    display: none;
  }
  .web-hidden {
    display: block;
  }

  .left-side {
    width: 100%;
    text-align: center;
  }
  .about-info {
    justify-content: center;
  }

  .pro-lang {
    text-align: center;
  }
  .pro-lang img{
    width: 23px;
  }
  .pro-lang {
    width: 100%;
  }
  /* experience */

  .qualification-tabs {
    justify-content: space-around;
  }

  .qualification {
    width: 100%;
  }

  .data {
    column-gap: 35px;
  }

  /* work */

  .work {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  /* footer */

  .footer {
    padding: 45px 25px 45px 25px;
  }
  .footer h1{
    font-size: 30px;
  }
}


